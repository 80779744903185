import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Papa from "papaparse";
import dayjs from "dayjs";
import {currencyFormat} from "helpers/formats";

const DAY_COLUMN = "day";
const VALUE_COLUMN = "balance";

const LiteChart = () => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const styles = {
    chart: {
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      overflow: "hidden",
      background: "transparent",
      boxSizing: "border-box",
      padding: "0px",
      margin: "0px",
    },
    loader: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "24px",
      color: "#555",
    },
    error: {
      color: "#FF0000",
      textAlign: "center",
      marginTop: "20px",
    },
  };

  const csvUrl = "https://www.robuxio.com/wp-content/uploads/lite_equity.csv";
  // const csvUrl = "http://localhost:8000/lite_equity.csv";

  const isValidRow = (row) => {
    return (
      row[DAY_COLUMN] &&
      !isNaN(parseFloat(row[VALUE_COLUMN]))
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); 

      try {
        const response = await axios.get(csvUrl);
        const parsedData = Papa.parse(response.data, {
          header: true,
          skipEmptyLines: true,
        });

        // Validate CSV headers
        const requiredHeaders = [DAY_COLUMN, VALUE_COLUMN];
        const headersValid = requiredHeaders.every((header) =>
          parsedData.meta.fields.includes(header)
        );

        if (!headersValid) {
          throw new Error("Invalid CSV format. Required headers are missing.");
        }

        const validData = parsedData.data.filter(isValidRow);

        const chartData = validData.map((row) => ({
          day: dayjs(row[DAY_COLUMN], "YYYY-MM-DD").toDate(),
          RobuxioLite: parseFloat(row[VALUE_COLUMN]),
        }));

        // Sort data by date in ascending order
        chartData.sort((a, b) => a.day - b.day);

        const robuxioLiteData = chartData.map((item) => ({
          x: item.day,
          y: item.RobuxioLite,
        }));

        setSeries([
          {
            name: "Robuxio Lite",
            data: robuxioLiteData,
          },
        ]);

        // Define the live start date as a variable
        const liveStartDate = dayjs("2024-09-01", "YYYY-MM-DD").toDate();

        const maxDataValue = Math.max(...chartData.map(item => item.RobuxioLite));
        const yAxisMax = Math.ceil(maxDataValue / 100) * 100; 

        setOptions({
          chart: {
            type: "line",
            height: "100%",
            width: "100%",
            foreColor: "#002745",
            background: "transparent",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: true,
              type: "x",
              autoScaleYaxis: true,
            },
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
            },
            sparkline: {
              enabled: false,
            },
            spacing: [0, 0, 0, 0],
          },
          colors: ["#008000"],
          stroke: {
            curve: "smooth",
            width: 3,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "MMM dd, yyyy",
              datetimeUTC: false,
              style: {
                fontSize: '10px',
                padding: 0,
                margin: 0,
              },
            },
            axisBorder: {
              show: true,
              color: '#e0e0e0',
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: true,
              color: '#e0e0e0',
            },
            crosshairs: {
              show: true,
            },
          },
          yaxis: {
            min: -50,
            max: yAxisMax,
            tickAmount: 15,
            floating: false,
            decimalsInFloat: 0,
            labels: {
              formatter: function (val) {
                const res = (val <= -40) ? -50 : Math.round(val / 50) * 50;
                return currencyFormat(res) + "%";
              },
              style: {
                colors: "#002745",
                fontSize: '10px',
              },
              offsetX: -2,
            },
            axisBorder: {
              show: true,
              color: '#e0e0e0',
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: true,
              color: '#e0e0e0',
            },
            crosshairs: {
              show: true,
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function(val) {
                return `${val.toFixed(2)}%`;
              }
            },
            x: {
              formatter: function (val) {
                return dayjs(val).format("MMM D, YYYY");
              },
            },
          },
          grid: {
            show: true,
            borderColor: "#e0e0e0",
            strokeDashArray: 2,
            position: 'back',
            padding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 5,
            },
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                chart: {
                  height: 300,
                  zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: true,
                  },
                },
                legend: {
                  position: "bottom",
                  horizontalAlign: "center",
                },
                xaxis: {
                  labels: {
                    fontSize: "8px",
                  },
                  tickAmount: 5,
                },
                yaxis: {
                  tickAmount: 8,
                  labels: {
                    formatter: function (val) {
                      if (val <= -40) return "-50%";
                      return Math.round(val / 50) * 50 + "%";
                    },
                    style: {
                      fontSize: '8px',
                    },
                  },
                },
              },
            },
            {
              breakpoint: 3840,
              options: {
                chart: {
                  height: '100%',
                  zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: true,
                  },
                },
                legend: {
                  position: "bottom",
                  horizontalAlign: "center",
                },
                xaxis: {
                  labels: {
                    fontSize: "10px",
                  },
                  tickAmount: 5,
                },
                yaxis: {
                  tickAmount: 8,
                  labels: {
                    formatter: function (val) {
                      if (val <= -40) return "-50%";
                      return Math.round(val / 50) * 50 + "%";
                    },
                    style: {
                      fontSize: '10px',
                    },
                  },
                },
              },
            },
          ],
          legend: {
            onItemClick: {
              toggleDataSeries: false, 
            },
            onItemHover: {
              highlightDataSeries: false, 
            },
          },
          annotations: {
            xaxis: [
              {
                x: liveStartDate.getTime(),
                x2: chartData[chartData.length - 1].day.getTime(),
                borderColor: "#FF0000",
                fillColor: "rgba(255, 0, 0, 0.1)",
                strokeDashArray: 4,
                label: {
                  borderColor: "#FF0000",
                  style: {
                    color: "#fff",
                    background: "#FF0000",
                  },
                  text: "LIVE",
                },
              },
            ],
          },
        });

        setLoading(false);
      } catch (err) {
        console.error("Error fetching or parsing data:", err);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [csvUrl]);

  return (
    <div style={styles.chart}>
      {loading ? (
        <div style={styles.loader}>Loading...</div>
      ) : error ? (
        <div style={styles.error}>{error}</div>
      ) : (
        <Chart
          options={options}
          series={series}
          type="line"
          height="100%"
          width="100%"
        />
      )}
    </div>
  );
};

export default LiteChart;
