// Date formats
export function epochToDate(ts){
    // ts = epoch timestamp
    // returns date obj
    return new Date(ts * 1000);
}

export function dateToHumanFormat(d) {
    return d.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}

export function dateToDatetimeFormat(d) {
    let year = d.getFullYear();
    let month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    let day = String(d.getDate()).padStart(2, '0');

    // Get the time components
    let hours = String(d.getHours()).padStart(2, '0');
    let minutes = String(d.getMinutes()).padStart(2, '0');
    let seconds = String(d.getSeconds()).padStart(2, '0');

    // Format the date and time
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// Currency
export function currencyFormat (amount, decimal_places = 2) {
    if (amount == null || Number.isNaN(amount)) return null;
    const rounded = parseFloat(parseFloat(amount).toFixed(decimal_places));
    const currency = rounded.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return currency.replace('$', '');
}
