import React, { useContext, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import { AuthContext } from 'context/Context';
import { getToken, getUser } from 'helpers/utils';
import Layout from 'layouts/Layout';
import MutatingDotsSpinner from 'components/common/MutatingDotsSpinner';
import ForgotPassword from 'components/forgot-password/ForgotPassword';
import KuCoinV3 from "components/profile/kucoinv3";
import RiskChartPublic from "components/risk-chart-public";
import LiteChart from "components/risk-chart-public/lite";

// Lazy-loaded components
const SimpleLogin = lazy(() => import('components/authentication/simple/Login'));
const Dashboard = lazy(() => import('components/dashboards/default'));
const Positions = lazy(() => import('components/dashboards/positions'));
const Trades = lazy(() => import('components/dashboards/trades'));
const Analytics2 = lazy(() => import('components/dashboards/analytics-2'));
const Profile = lazy(() => import('components/profile'));
const AccountSettingForm = lazy(() => import('components/settings/account-settings'));
const SupportForm = lazy(() => import('components/support'));
const Billing = lazy(() => import("components/management/billing"));


const AppRoutes = () => {
  const { authState, authDispatch } = useContext(AuthContext);
  const { redirectToPath } = authState;
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (getUser()) {
      authDispatch({
        type: 'SET_USER',
        payload: {
          user: true
        }
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    const checkTokenAvailability = () => {
      if (!getToken() || !getUser()) {
        authDispatch({
          type: 'SET_USER',
          payload: {
            user: false
          }
        });
        // Store the path if unauthenticated
      }
    };

    // Initial check when the component mounts
    checkTokenAvailability();

    // Set up polling to periodically check token's availability
    const pollInterval = setInterval(checkTokenAvailability, 1000); // Adjust interval as needed

    return () => {
      clearInterval(pollInterval); // Clean up the interval on unmount
    };
  }, []);

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-content-center w-100' style={{ height: '100vh' }}>
        <MutatingDotsSpinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="live" element={<RiskChartPublic />} />
      <Route path="lite" element={<LiteChart />} />
      <Route element={<Layout />}>
        {!authState.user ? (
          <Route element={<AuthSimpleLayout />}>
            <Route path="/login" element={<SimpleLogin />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path='/forgot-password'  element={<ForgotPassword/>}/>
          </Route>
        ) : (
          <>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="positions" element={<Positions />} />
              <Route path="trades" element={<Trades />} />

              <Route path="billing" element={<Billing />} />

              <Route path="account" element={<AccountSettingForm />} />
              <Route path="profile" element={<Profile />} />

              <Route path="contact-us" element={<SupportForm />} />

              {/*<Route path="kucoinv3" element={<KuCoinV3 />} />*/}
              <Route path="*" element={<Navigate to={redirectToPath ? redirectToPath : "/"} />} />
            </Route>
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
